.container {
    color: #333;
    margin: 0 auto;
    text-align: center;
    flex-grow: 0;

    align-items: center;
    font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
}

li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
    color: white;
}

li span {
    display: block;
    font-size: 1.5rem;
    /*border: 2px solid white;*/
    /*border-radius: 2px;*/
    /*background-color: white;*/
}

#content {
    display: none;
    padding: 1rem;
}

@media all and (max-width: 768px) {

    li {
        font-size: 1.125rem;
        padding: .75rem;
    }

    li span {
        font-size: 1.5rem;
        /*font-size: 3.375rem;*/
    }
}
